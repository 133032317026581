* {
	-webkit-tap-highlight-color: transparent;
}

html {
	font-size: 16px;
}

body {
	overflow-y: scroll;
}

a {
	text-decoration: none;
	color: inherit;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 50px white inset;
	box-shadow: 0 0 0 50px white inset;
	-webkit-text-fill-color: #333;
}

textarea,
input[type='text'],
input[type='tel'],
input[type='password'],
input[type='number'] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

input[type='number'] {
	-moz-appearance: textfield;
}

html,
body,
#root {
	height: 100%;
}

a {
	text-decoration: none;
}

body {
	overflow-y: scroll;
}
